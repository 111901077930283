.placeholder {
    margin-bottom: $grid-gutter-width;
    text-align: center;

    .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        border-radius: $border-radius;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

        h1 {
            font-size: 1rem;
            color: $white;
            padding-top: 0.8rem;
            padding-bottom: 0.8rem;
            margin-bottom: 0;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
        }

        .information {
            height: 100%;
            font-size: 1.3rem;
            padding: 0.8rem;
            color: rgba(255, 255, 255, 0.6);
            background: rgba(0, 0, 0, 0.08);
        }
    }
}
