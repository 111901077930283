.simple {
    margin-bottom: $grid-gutter-width;

    .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: $white; // !important;
        border-radius: $border-radius;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

        header {
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
        }
        footer {
            box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.15);
        }

        header,
        footer {
            color: $gray-900;
            padding: 0.8rem;
            h1 {
                font-size: 1rem;
                margin-bottom: 0;
                padding: 0;
            }
            ul {
                margin-bottom: 0;
            }
        }

        .information {
            height: 100%;
            padding: 0.8rem;
            color: $gray-700;
            background: rgba(0, 0, 0, 0.045);
        }
    }
}
