//
// Color System
//

$white:  #fff !default;
$gray-100: #fafafa !default;
$gray-200: #e9ebee !default; //#e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #22262e !default;
$gray-900: #131524 !default; // #1b1e24
$black:  #000 !default;

$grays: (
    100: $gray-100,
    200: $gray-200,
    300: $gray-300,
    400: $gray-400,
    500: $gray-500,
    600: $gray-600,
    700: $gray-700,
    800: $gray-800,
    900: $gray-900
) !default;

$blue:    #0090d9 !default; //#007bff
$indigo:  #6610f2 !default;
$purple:  #7148c1 !default; //#6f42c1  #735f87
$pink:    #e83e8c !default;
$red:     #f03949 !default; //#dc3545 #f35958
$orange:  #fd7e14 !default;
$yellow:  #fbb05e !default; //#ffc107
$green:   #28a745 !default;
$teal:    #15c360 !default; //#20c997 #0aa699
$cyan:    #1f3853 !default; //#17a2b8

$colors: (
    blue: $blue,
    indigo: $indigo,
    purple: $purple,
    pink: $pink,
    red: $red,
    orange: $orange,
    yellow: $yellow,
    green: $green,
    teal: $teal,
    cyan: $cyan,
    white: $white,
    gray: $gray-200,
    gray-dark: $gray-900
) !default;

$theme-colors: (
    primary: $blue,
    purple: $purple,
    secondary: $gray-600,
    success: $green,
    info: $cyan,
    warning: $yellow,
    danger: $red,
    light: $white,
    gray: $gray-200,
    dark: $gray-900
) !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            true !default;
$enable-shadows:            true !default;
$enable-gradients:          true !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

//$grid-gutter-width: 12px !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px
) !default;


// Components
//
// Define common padding and border radius sizes and more.
//
$border-radius:          0.15rem !default;
$border-radius-lg:       0.2rem !default;
$border-radius-sm:       0.1rem !default;


// Body
//
// Settings for the `<body>` element.

$body-bg:       $gray-200 !default;
$body-color:    $gray-900 !default;


// Links
//
// Style anchor elements.

$link-color: $gray-700 !default;
$link-hover-color: lighten($link-color, 25%) !default;


// Components
//
// Define common padding and border radius sizes and more.

$component-active-color: $white !default;
$component-active-bg: rgba(255, 255, 255, 0.085) !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:"Ubuntu", "Segoe UI", "Helvetica Neue", arial, sans-serif !default;

$font-size-base: 0.875rem !default;

$h1-font-size: 1.5rem !default;
$h2-font-size: 1.3rem !default;
$h3-font-size: 1.1rem !default;
$h4-font-size: 1rem !default;
$h5-font-size: 0.8rem !default;
$h6-font-size: 0.6rem !default;


//
// Navs
//

$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg:    $component-active-bg !default;


//
// Navbar
//

$navbar-toggler-padding-y:           0.25rem !default;
$navbar-toggler-padding-x:           0.75rem !default;
$navbar-light-toggler-border-color:  transparent !default;


//
// Pagination
//

$pagination-color:                     $gray-700 !default;
$pagination-bg:                        rgba($black, 0.045) !default;
$pagination-border-color:              #ddd !default;

$pagination-hover-color:               $gray-900 !default;
$pagination-hover-bg:                  rgba($black, 0.01) !default;
$pagination-hover-border-color:        #ddd !default;

$pagination-active-color:              $white !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            0.6rem !default;
$table-cell-padding-sm:         0.3rem !default;

$table-bg:                      transparent !default;
$table-accent-bg:               rgba($black, 0.03) !default;
$table-hover-bg:                rgba($black, 0.085) !default;
$table-active-bg:               $table-hover-bg !default;

$table-border-color:            $gray-200 !default;

$table-head-bg:                 $gray-200 !default;
$table-head-color:              $gray-200 !default;

$table-inverse-bg:              $gray-900 !default;
$table-inverse-accent-bg:       rgba($white, 0.05) !default;
$table-inverse-hover-bg:        rgba($white, 0.075) !default;
$table-inverse-border-color:    lighten($gray-900, 7.5%) !default;
