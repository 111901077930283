.container-front {
    .nav-maintop {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        border-bottom: solid 3px $blue;
    }
}


.container-intra {
    .nav-maintop.bg-light {
        @include media-breakpoint-down(md) {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
            border-bottom: solid 3px $blue;
        }
    }
    .nav-maintop {
        .navbar-collapse-desktop {
            border-bottom: solid 3px $blue;
            height: 60px;
        }
    }
}


.nav-maintop {
    height: $height-nav-maintop;
    padding: 0;

    .navbar-brand {
        margin-right: 0;
        align-self: stretch;

        @include media-breakpoint-down(md) {
            background-color: transparent !important;
            justify-content: flex-start;
            height: 60px;
        }
    }

    &.fixed-top {
        position: fixed;
        width: 100%;
        top: 0;
        right: auto;
        left: auto;
    }

    .bg-dark {
        .nav-maintop-title {
            h3 {
                color: $white;

                @include media-breakpoint-down(md) {
                    color: $black !important;
                    justify-content: flex-start;
                }
            }

            @include media-breakpoint-down(md) {
                justify-content: flex-start;
            }
        }
    }

    .nav-maintop-title {
        display: flex;
        min-height: 100%;
        flex-flow: row nowrap;
        justify-content: center;

        @include media-breakpoint-down(md) {
            justify-content: flex-start;
        }

        h3 {
            span {
                font-family: Caveat;
                font-size: 1.3rem;
                margin-right: 0.2rem;
                letter-spacing: 1px;
                font-weight: bold;
            }

            margin: 0;
            padding: 0;
            border: none;
            display: flex;
            flex: 1 1 auto;
            align-items: center;
            justify-content: center;
            font-weight: lighter;

            @include media-breakpoint-down(md) {
                justify-content: flex-start;
            }
        }
    }

    .nav-link {
        color: $gray-600;

        @include hover-focus {
            color: $black;
        }
    }

    .navbar-collapse-mobile {

        .nav-item {
            width: 100%;

            .nav-link:hover,
            .nav-link:focus {
                text-decoration: none;
                background-color: rgba(255, 255, 255, 0.085);
                color: $white;
            }

            a {
                padding-left: 30px;
                font-size: 0.95rem;
                line-height: 1.4rem;
                color: $gray-600;
            }

            .ic {
                width: 25px;
                text-align: center;
                color: $gray-700;
                font-size: 1.1rem;
            }

            .active {
                color: $white;
                font-weight: bold;
                background-color: rgba(255, 255, 255, 0);

                .ic {
                    color: lighten($blue, 10%);
                }
            }
        }
    }
}
