.profil-min {
    display: flex;
    align-items: center;

    .rounded-circle {
        position: relative;
        width: 35px;
        height: 35px;
        overflow: hidden;

        img {
            position: absolute;
            width: 100%;

            &.user-profil {
                top: -25%;
            }

            &.default-profil {
                top: 0%;
            }
        }
    }

    .whoami,
    .current_cursus {
        margin-left: 0.8rem;
        font-size: 0.8rem;
        color: $gray-700;
    }

    .current_cursus {
        text-align: right;
    }
}

