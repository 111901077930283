/* .navbar {

    .navbar-toggler {

        outline: none;

        .navbar-toggler-icon {
            display: inline-block;
            width: 1.1em;
            height: 1.1em;
            background-size: 100% 100%;
        }
    }
} */
