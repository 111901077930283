.alert-auto-container {

    .alert-auto,
    .alert-perm {
        margin-left: -10px;
        margin-right: -10px;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .alert-login {
        margin-left: 0;
        margin-right: 0;
    }

    .alert-success {
        border-color: darken($green, 5%);
    }

    .alert-warning {
        border-color: darken($yellow, 10%);
    }

    .alert-danger {
        border-color: darken($red, 5%);
    }
}
