form[name="App_usertype"] {


    .page-profil-photo {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    select[name*="birthDate"] {
        flex-grow: 1;
    }

    .col-staff {
        .ignore-other-col {
            width: 100%;
            max-width: 100%;
            flex-basis: auto;
        }
    }
}

#App_usertype_birthDate.form-control.is-invalid.form-inline {
    border: none;
    padding: 0;
    background: none;

    select {
        border-color: #f03949;
    }
}

.switch-user-case-parent {
    position: absolute;
    left: 50%;

    .switch-user-case {

        a {
            color: $white;
        }

        font-weight: bold;
        position: relative;
        background-color: $red;
        border: 3px solid darken($red, 10);
        border-top: none;
        z-index: 10000;
        padding: 10px;
        border-radius: 0 0 5px 5px;
        left: -50%;
        top: -35px;
        transition: top 0.25s;

        &:hover {
            top: 0;

            img {
                opacity: 0;
            }
        }
    }
}
