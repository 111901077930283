.sidebar {
    position: fixed;
    top: $height-nav-maintop;
    bottom: 0;
    left: 0;
    z-index: 1000;
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    border: none;
    padding-top: 15px;


    @include media-breakpoint-down(xs) {
        position: relative;
        top: 0;
    }


    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 4px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primary;
    }


    .nav-item {
        width: 100%;

        .nav-link:hover,
        .nav-link:focus {
            text-decoration: none;
            background-color: rgba(255, 255, 255, 0.085);
            color: $white;
        }

        a {
            font-size: 0.95rem;
            color: $gray-600;
        }

        .ic {
            width: 25px;
            text-align: center;
            color: $gray-700;
            font-size: 1.1rem;
        }

        .active {
            color: $white;
            font-weight: bold;
            background-color: rgba(255, 255, 255, 0);

            .ic {
                color: lighten($blue, 10%);
            }
        }
    }

    .nav-link {
        border-radius: 0;
    }

    .info-version {
        position: absolute;
        bottom: 10px;
        width: 100%;
        text-align: center;
        color: $gray-600;

        &::before {
            content: "v";
        }
    }
}
