.card-block-body {
    margin-left: -10px;
    margin-right: -10px;

    .accordion-stagiaire {
        @include media-breakpoint-down(xs) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
.card-block-body-js {
    overflow: hidden;
}

.card-glue-hn {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

    .card-body-hydrate-150 {
        min-height: 60px;
    }
    h3,
    h4 {
        text-align: center;
        margin: 0;
        padding: 0;
    }

    h4 {
        padding-top: 5px;
        font-size: 12px;
    }
}

.card-dashboard {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-top: none;
    border-bottom: none;


    .btn-block {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }


        .ic {
            min-width: 55px;
            min-height: 55px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
        }

        .card-title {
            font-size: 0.9rem;
            margin-right: 15px;
            margin-left: 15px;
        }

        .card-title,
        p {
            margin-bottom: 0;
        }

        .card-text {
            font-size: 1.8rem;
            color: #777;
        }
    }
}

.card-absence {
    width: 100%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.125);


    h6 {
        margin: 0;
        background-color: rgba(0, 0, 0, 0.125);
        font-weight: bold;
        padding: 0.5rem;
        font-size: 0.65rem;
        text-align: center;
    }

    .card-title {
        margin-bottom: 0;

        .blockquote {
            margin-bottom: 0;
            text-transform: capitalize;

            .blockquote-footer::before {
                display: none;
            }

            p {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    .card-body {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .card-footer {
        padding: 0;
        .btn {
            margin: 0;
        }
    }

    .btn-group {
        width: 100%;
        a {
            &:hover {
                color: $white;
            }

            color: $white;
            display: block;
            width: 50%;

            @include media-breakpoint-down(md) {
                padding: 0.25rem 0.25rem;
            }
        }
        &.no-statement {
            a {
                width: 50%;
            }
            .btn-classic {
                width: 20%;

                @include media-breakpoint-down(md) {
                    width: 25%;
                }
            }
        }
        &.statement {
            a {
                width: 100%;
            }
            .btn-classic {
                width: 20%;

                @include media-breakpoint-down(md) {
                    width: 25%;
                }

                @include media-breakpoint-down(xs) {
                    width: 33%;
                }
            }
        }
    }

    img {
        width: 100%;
        height: auto;
    }
}

.card img {
    max-width: 100%;
    height: auto;
}
