.popin {
    > div {
        position: fixed;
        width: 50%;
        background: $white;
        top: calc(50% - 30%);
        left: 25%;
        z-index: 10000;
        padding: 10px 0;


        @include media-breakpoint-down(lg) {
            width: 75%;
            top: calc(50% - 45%);
            left: calc(25% / 2);
        }


        &.absence-details {
            display: flex;

            div {
                .ui-slider-handle {
                    &:last-child {
                        /* display: none; */
                    }
                }
            }

            .cross {
                position: absolute;
                top: 0;
                right: 0;
                background: #dc143c;
                padding: 0 6px;
                color: #fff;
                font-weight: bold;
                z-index: 1000;
                cursor: pointer;
            }

            .absence-profil {
                //width:30%;
                height: auto;

                @include media-breakpoint-down(md) {
                    text-align: center;
                }
            }

            .absence-status {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;

                > div {
                    margin-bottom: 1rem;
                }

                .title-slide {
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.95rem;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }

                .slider-range {
                    background-color: $red;

                    .ui-slider-range {
                        background-color: $green;
                    }
                }

                .row {
                    margin: 0;
                }
                //padding: 10px 20px;
                //width:70%;
                //height:auto;
            }
        }
    }
    &::before {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: block;
        background: rgba(0, 0, 0, 0.7);
        content: "";
        z-index: 9999;
    }
}
