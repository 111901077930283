form {
    legend {
        font-size: inherit;
    }
    label {
        cursor: pointer;
    }
}

select {
    &.form-control.inline-choice {
        display: inline-block;
        width: 30%;
        vertical-align: top;
    }
}

.custom-select.filter-limit {
    height: calc(1.6rem + 2px);
    padding: 0.175rem 1.75rem 0.175rem 0.75rem;
    line-height: 1.6;
    -moz-appearance: none;
    -webkit-appearance: none;
}

label[for="App_usertype_currentPassword"] {
    font-weight: bold;
}
