.btn-disable,
.btn-disable:hover {
    color: $gray-400;
}


.btn:focus {
    box-shadow: none;
}

table th .btn-nano,
table td .btn-nano,
.table th .btn-nano,
.table td .btn-nano {
    margin: 0;
    margin-bottom: 3px;
}

.btn-group.btn-group-toggle {
    .btn {
        margin: 0;
        margin-bottom: 3px;
    }
}

.btn-accordion {
    span {
        padding-left: 5px;
        padding-right: 10px;
    }
}

.btn-accordion::after {
    display: inline-block;
    font: normal normal normal 10px FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f054";
    transform: rotate(90deg);
    transition: all linear 0.25s;
}
.btn-accordion.collapsed::after {
    transform: rotate(0deg);
}

