@charset "UTF-8";

// declaration de variable / override de variable vendor
@import "variables/awesome.scss";
@import "variables/bootstrap.scss";
@import "variables/theme.scss";

// vendor
@import "../vendor-front/bootstrap/scss/bootstrap.scss";
@import "../vendor-front/font-awesome/scss/font-awesome.scss";
//@import "../vendor-front/font-awesome-animation/src/_font-awesome-animation.scss";

// theme
@import "_tags.scss";
@import "_blocks.scss";
@import "_menus.scss";
@import "_pages.scss";
