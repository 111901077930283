.table {
    &.table-success {
        border-color: #64c896;
        background: lighten(#64c896, 35);
        th,
        td {
            border-color: lighten(#64c896, 15);
            background: lighten(#64c896, 35);
            .blockquote-footer {
                color: darken(#64c896, 10);
            }
            input {
                border: 1px solid #64c896;
                &:focus {
                    outline: #64c896 auto 5px;
                }
            }
        }
    }

    &.table-error {
        border-color: #c86464;
        background: lighten(#c86464, 35);
        th,
        td {
            border-color: lighten(#c86464, 15);
            background: lighten(#c86464, 35);
            .blockquote-footer {
                color: darken(#c86464, 10);
            }
            input {
                border: 1px solid #c86464;
                &:focus {
                    outline: #c86464 auto 5px;
                }
            }
        }
    }

    &.table-warning {
        border-color: #c8a364;
        background: lighten(#c8a364, 35);
        th,
        td {
            border-color: lighten(#c8a364, 15);
            background: lighten(#c8a364, 35);
            .blockquote-footer {
                color: darken(#c8a364, 10);
            }
            input {
                border: 1px solid #c8a364;
                &:focus {
                    outline: #c8a364 auto 5px;
                }
            }
        }
    }

    &.cursus-detail {
        .info-coef-mat {
            white-space: nowrap;
            padding-left: 10px;
            font-size: 80%;
        }
        table {
            blockquote {
                margin: 0;
            }
        }
        td {
            border-top-width: 5px;
        }
        .for-point {
            white-space: nowrap;
        }
    }

    th,
    td {
        vertical-align: middle;

        p {
            margin: 0;
        }

        .btn-nano {
            margin-left: 2px;
            margin-right: 2px;
        }
    }

    .table-action {
        text-align: right;
        padding-right: 0;
    }

    thead th {
        border-top: 0;
        border-bottom-width: 3px;
    }
}

.table-responsive {
    overflow-x: hidden;
}


.container-table-scrollable {
    background-color: #c86464;
    overflow-x: scroll;
    height: 70vh;

    .clamp {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        cursor: default;
    }

    .sticky-name,
    .sticky-name-label {
        border-left: 2px;
        position: sticky;
        left: 0;
        background: rgb(72, 88, 99);
        color: #fff;
    }

    .sticky-name-label {
        top: 0;
        z-index: 100;
    }

    table {
        position: relative;
        overflow-y: scroll;
        overflow-x: scroll;

        td:hover {
            background-color: rgba(0, 0, 0, 0.185);
            cursor: pointer;
        }
        th {
            border-top: 2px;
            background: rgb(72, 88, 99);
            color: #fff;
            position: sticky;
            top: 0;
            box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
            vertical-align: top;
            text-align: center;
            max-height: 75px;
            max-width: 100px;
        }
    }
}
