.filter-table {

    .card {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-header {
        border-bottom: none;
        padding: $padding-card-hearder;
    }

    .card-block {
        padding: 0.75rem;

        .form-group {
            margin-bottom: $padding-card-hearder;
        }
    }
}

.text-info-toggle {
    cursor: pointer;
    user-select: none;
}
