@import "blocks/_body-content.scss";
@import "blocks/_sidebar.scss";
@import "blocks/_placeholder.scss";
@import "blocks/_simple.scss";
@import "blocks/_filter-table.scss";
@import "blocks/_btn.scss";
@import "blocks/_card.scss";
@import "blocks/_profil-min.scss";
@import "blocks/_form-horizontal.scss";
@import "blocks/_form-login.scss";
@import "blocks/_form-register.scss";
@import "blocks/_alert-auto.scss";
@import "blocks/_popin.scss";
@import "blocks/_pagination.scss";
@import "blocks/card-choice-cursus";
