.btn {
    white-space: nowrap;
}

.btn-nano {
    padding: 0.25rem 0.4rem;
    font-size: 0.7rem;
    line-height: 1.5;
}

.badge-alert {
    pointer-events: none;
}

.btn:not(:disabled):not(.disabled).not-allowed-cursor {
    cursor: not-allowed;
}
.btn:not(:disabled):not(.disabled).btn-help-cursor {
    cursor: help;
}

.btn-group {
    .btn-nano:not(:last-child):not(:first-child) {
        margin-left: 0;
    }
}


span.badge {
    cursor: default;
}
