.form-register {
    margin-top: 50px;

    .links {
        text-align: center;
        a {
            color: $blue;
        }
    }

    .ignore-other-col {

        .col,
        [class*="col-"] {
            width: 100%;
            max-width: 100%;
        }

        select {
            flex-grow: 1;
        }

        margin-bottom: 15px;
    }
}
