

.choice-cursus-block {

    // fucking block parent
    margin: -25px;
    background: #e9ebee !important;
    display: flex;


    /* .block-heading {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        margin-bottom: 50px;

        h1 {
            padding-top: 35px;
            padding-bottom: 20px;
            text-align: center;
            font-weight: bold;
        }

        p {
            padding-bottom: 20px;
            text-align: center;
            max-width: 50%;
            margin: auto;
            opacity: 0.85;
        }
    } */

    .col-items {
        position: relative;
        display: flex;
    }

    .item {
        background-color: #fff;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
        border-top: 2px solid #5ea4f3;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;

        &.disable {
            opacity: 0.8;
            border-top-color: $gray-600;

            .ribbon {
                background: $gray-600;
            }
        }

        .btn-block {
            margin-top: 20px;
            font-weight: 600;
            text-transform: uppercase;
        }

        .heading {
            display: flex;
            flex-direction: column;
            text-align: center;
            h4 {
                height: 45px;
            }
        }

        .ribbon {
            width: 160px;
            height: 32px;
            font-size: 12px;
            text-align: center;
            color: #fff;
            font-weight: bold;
            box-shadow: 0 2px 3px rgba(136, 136, 136, 0.25);
            background: #4dbe3b;
            transform: rotate(45deg);
            position: absolute;
            right: -42px;
            top: 20px;
            padding-top: 7px;
        }

        .features {
            border-top: 1px solid rgba(0, 0, 0, 0.15);
            display: flex;
            flex-direction: column;

            .feature {
                font-weight: 600;
            }
            h4 {
                text-align: center;
                font-size: 14px;
                padding: 5px;
            }
        }

        .buy-now {
            button {
                text-align: center;
                margin: auto;
                font-weight: 600;
                padding: 9px 0;
            }
        }
    }

    .col-md-5:not(:last-child) .item {
        margin-bottom: 30px;
    }
}
