.form-login {
    margin-top: 50px;
}

form,
.form-login {

    .links {
        text-align: center;
    }

    a,
    a:hover {
        color: $blue;
    }
}
