form.col-form {
    @include media-breakpoint-up(md) {
        & > [id*="App_"] {
            display: flex;
            flex-wrap: wrap;

            .form-group {
                width: 50%;
                margin-bottom: 1rem;

                &:nth-child(2n+1) {
                    padding-right: 15px;
                }
                &:nth-child(2n) {
                    padding-left: 15px;
                }
                &:last-child {
                    padding-right: 0;
                    padding-left: 0;
                }
            }

            > .form-group {

                select {
                    flex-grow: 1;
                }

                > .form-check {
                    padding-top: 25px;
                }
            }
        }
    }
}


form {

    legend.col-form-label {
        padding: 0;
        display: inline-block;
        margin-bottom: 0.5rem;
    }

    textarea {
        min-height: 200px;
    }

    .checkbox-inline {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        flex-grow: 1;

        .form-check {
            margin-right: 20px;
        }

        .form-check-input {
            margin-left: -0.9rem;
        }
    }

    .form-control:focus {
        border-color: $gray-400;
        outline: $primary auto 5px;
    }
}
