
.title-page-dashboard {
    display: flex;
    justify-content: space-between;

    .btn-group,
    .title-info {
        align-items: center;
        vertical-align: initial;
        display: flex;
    }

    .title-info {
        justify-content: flex-end;
    }
}
.accordion-stagiaire {
    .card-header {
        padding: 15px;
        cursor: pointer;
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid transparent;

        .arrow-accordion,
        .arrow-accordion:active,
        .arrow-accordion:focus,
        .arrow-accordion:active:focus {
            display: flex;
            width: 100%;
            box-shadow: none;
            text-decoration: none;
            padding-left: 0;
            padding-right: 0;
            align-items: center;

            .row {
                padding-left: 35px;
                align-items: center;
                flex-grow: 1;
            }
        }
    }

    .card-body {
        //padding: 1.5rem 0.5rem 0.5rem 0.5rem;
    }
}

.arrow-accordion::before {
    position: absolute;
    display: inline-block;
    opacity: 0.5;
    font: normal normal normal 20px FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f054";
    transform: rotate(90deg);
    transition: all linear 0.25s;
}
.arrow-accordion.collapsed::before {
    transform: rotate(0deg);
}
