h1,
h2,
h3,
h4,
h5,
h6 {
    color: $gray-700;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

main {
    > h2 {
        border-top: 1px solid $gray-500;
        padding-top: 1.8rem;
        margin-top: 1.6rem;
    }
}


.noselect {
    user-select: none;
}
