@charset "UTF-8";

.nav-question-qcm {
    .active {
        color: $gray-800;
        font-weight: bold;
    }

    .nav-item {
        display: flex;

        .hidden {
            color: transparent;
        }

        .disable {
            &:hover {
                background: transparent;
                cursor: default;
            }
        }

        .nav-link {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        .btn-question {
            &::before {
                content: "";
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                color: transparent;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        .btn-question-good {
            &::before {
                content: "\f058";
                font: normal normal normal 16px/1 FontAwesome;
                color: $green;
            }
        }

        .btn-question-goil {
            &::before {
                content: "\f06a";
                font: normal normal normal 16px/1 FontAwesome;
                color: $orange;
            }
        }

        .btn-question-fail {
            &::before {
                content: "\f057";
                font: normal normal normal 16px/1 FontAwesome;
                color: $red;
            }
        }

        .question-got-response {
            &::before {
                color: inherit;
            }
        }
    }
}


.container-do-qcm {

    .lightbox {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: #e9ebee;
    }

    .lightbox-content {
        width: 95%;
        min-height: 80%;
        left: 5%;
        top: 3%;
        margin-bottom: 10%;
        background: $white;
        position: relative;
        //border-bottom: solid 3px #0090d9;
    }


    .link-briefing:hover {
        cursor: pointer;
    }

    .card-text,
    .card-footer {
        .link-briefing {
            text-decoration: underline;
        }
    }


    .card-qcm {
        h1 {
            text-align: center;
        }

        .response {
            display: flex;
            align-items: center;

            .ic {
                font-size: 1.2rem;
                margin-right: 0.5rem;
            }

            > span {
                display: flex;
                align-items: center;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .card-header {
            display: flex;
            align-items: center;

            .chrono {
                margin-left: 10px;
            }

            .progress {
                flex-grow: 1;
                display: flex;
                height: 0.4rem;
                margin-left: -1px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            .ic {
                color: $primary;
                font-size: 1.5rem;
            }
        }

        .card-footer {
            .alert {
                margin-bottom: 0;
            }
        }
    }
}
